import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, DialogTitle, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './addcase.module.scss';
import 'src/style.css';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';
// import AdvanceSearch from './Advance-basic';
import { toast } from 'react-toastify';
import { eDiscoveryUrl } from 'src/common/routes';
import { useLocation, useNavigate } from 'react-router';

function HeaderMenu() {
  let navigate = useNavigate();
  const [languageArray] = useGlobalState('languageArray');
  const [selectedTab, setselectedTab] = useState(0);
  const [headerMenu] = useGlobalState('headerMenu');

  const RefreshDashboard = () => {
    setGlobalState('ErrorFoundGlobal', true);
    setselectedTab(0)
    navigate(eDiscoveryUrl.CaseS3 + '/1');
    
  };

  const OpenRules = () => {
    setselectedTab(1)
    navigate(eDiscoveryUrl.Rules);
    
  };

  const OpenEditorialClaims = () => {
    setselectedTab(2)
    navigate(eDiscoveryUrl.EditorialClaims);
    
  };

  useEffect(() => {
    // setselectedTab(headerMenu);
    const location = String(window.location.href);
    console.log('location => ')
    console.log(location)
    if(location.includes('dashboard/folder/')) {
      setselectedTab(0)
    } else if(location.includes('/rules')) {
      setselectedTab(1)
    } else if(location.includes('editorial-claims')) {
      setselectedTab(2)
    }

  }, []);

  // if (headerMenu) {
  //   setselectedTab(headerMenu);
  // }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="stretch"
        display="flex"
        flexDirection="column"
        // spacing={3}
        style={{ marginLeft: '10px' }}
      >
        <Grid item lg={12}>
          <h3
            className={
              selectedTab == 0
                ? styles.menuItemLabelActive
                : styles.menuItemLabel
            }
            onClick={RefreshDashboard}
          >
            {languageArray.length > 0 && languageArray
              ? languageArray[7]
              : 'Documents'}
          </h3>
          <h3
            className={
              selectedTab == 1
                ? styles.menuItemLabelActive
                : styles.menuItemLabel
            }
            onClick={OpenRules}
          >
            {languageArray.length > 0 && languageArray
              ? languageArray[223]
              : 'Claims dictionary'}
          </h3>
          <h3
            className={
              selectedTab == 2
                ? styles.menuItemLabelActive
                : styles.menuItemLabel
            }
            onClick={OpenEditorialClaims}
          >
            Editorial Guideline
          </h3>
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderMenu;
