import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
  reload: true,
  modaltype: null,
  modalmessage: null,
  sessionerror: null,
  loggedin: false,
  charts1: true,
  charts2: true,
  extractdoc: null,
  exportdoc: true,
  caseName: '',
  resultcase: null,
  resultdoc: null,
  resultfile: null,
  resultfilename: null,
  resultshow: false,
  resultreload: true,
  PIIKeyword: null,
  PIIKeywordstatus: false,
  esearch: null,
  PIICase: null,
  FileExplorer: false,
  ReviewTools: false,
  PIItag: null,
  PIIstartoffsets: null,
  PIIendoffsets: null,
  PIItagcolor: null,
  PIIcontent: null,
  PIIOldTag: null,
  SavePIIs: false,
  ResetPIIs: false,
  DeletePIIs: false,
  AllPIITags: null,
  ReviewEmbedType: null,
  ReviewFolderID: null,
  SearchParams: null,
  SearchReload: false,
  SearchType: 1,
  SearchBarWidth: '60px',
  AdvanceQuery: {},
  ShowCoding: true,
  UpdatePIIS: false,
  CloseModal: false,
  relationTag: null,
  relationStart: null,
  relationEnd: null,
  relationRow: null,
  tagRow: null,
  relationtext: null,
  relationselected: false,
  relationLabel: null,
  RelationPIIs: null,
  RelationPIIsDefault: null,
  PIICounter: 0,
  RemoveRelation: false,
  OriginalRelation: null,
  OriginalText: null,
  UploadedPIIids: '',
  UploadedPIICount: 0,
  DownloadPIIs: false,
  ExportPIIs: false,
  PIIReviewer: false,
  modelType: 1,
  AdditionalPIIRow: null,
  AdditionalPIITag: null,
  AdditionalPIIText: null,
  AdditionalPIIstart: null,
  AdditionalPIIend: null,
  removeStart: null,
  removeEnd: null,
  removeRow: null,
  removeText: null,
  removeTag: null,
  removeCount: null,
  TagginArray: null,
  PIIColors: null,
  rerelation: null,
  DeleteCase: true,
  Config: null,
  BucketType: null,
  BucketPath: null,
  accessKeyId: null,
  secretAccessKey: null,
  BucketRegion: null,
  ErrorFoundGlobal: true,
  improvementFounds: 0,
  issueLine: 1,
  issueSelect: false,
  BrandsFounds: 0,
  BrandsLine: 1,
  BrandsSelect: false,
  AllLine: 1,
  AllSelect: false,
  languageType: 'EnglishCommercial',
  languageArray: [],
  ThumbArray: [],
  openTagModal: 0,
  processServicesStatus: false,
  DownloadAllCSV: false,
  DownloadAllStatus: false,
  updateAllTab: false,
  updateClaimsTab: false,
  updateBrandsTab: false,
  updateChineseTab: false,
  ExportRulesStatus: false,
  UploadedFiles: [],
  ProcessFile: false,
  RemovecId: 0,
  RemoveHighlight: false,
  processFilesIds: '',
  processFiles: false,
  createServiceFirst: false,
  headerMenu: 0,
  collapse: true,
  expandRows: false,
  rowNumber: 0,
  refreshRowClick: 0,
  HighlightFilenames: '',
  UserHighlight: null,
  UserHighlightUpdate: false,
  NonclickableID: 0,
  ReloadClaims: false,
  DeleteId: 0,
  DeleteHighlight: false,
  rulesArray: [],
  PlusClaim: false,
  DeleteClaim: false,
  DeleteCount: 0,
  Updatesidebar: false,
  UpdatesidebarArray: [],
  Reducesidebar: false,
  ReduceParentId: 0,
  deletedThumbsArray: [],
  stopFile: true,
  getImageId: 0
});

export { useGlobalState, setGlobalState };
