import { Box } from '@mui/material';
// import HeaderSearch from './Search';

function HeaderButtons() {
  return (
    <Box>
      {/* <HeaderSearch /> */}
    </Box>
  );
}

export default HeaderButtons;
