import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import AccountSettings from './content/pages/Settings';
import AccountSettingsS3 from './content/pages/Setting';
import ProtectedRoute, { SessionMaintained } from './components/ProtectedRoute';
import { eDiscoveryUrl, Page } from './common/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Authentication = Loader(
  lazy(() => import('src/content/authentication/login2'))
);

const MSearchBox = Loader(
  lazy(() => import('src/content/mbotSearch/SearchBox'))
);
const MSearchView = Loader(
  lazy(() => import('src/content/mbotSearch/SearchView'))
);

const Register = Loader(
  lazy(() => import('src/content/authentication/register'))
);

const AWSauth = Loader(
  lazy(() => import('src/content/authentication/Awsauth'))
);

const AccountCreated = Loader(
  lazy(() => import('src/content/authentication/Accountcreated'))
);

const Forgetemail = Loader(
  lazy(
    () => import('src/content/authentication/forgetpassword/forgetcodeemail')
  )
);

const Forgetauth = Loader(
  lazy(() => import('src/content/authentication/forgetpassword/forgetcode'))
);

const CaseSettingsS3 = Loader(
  lazy(() => import('src/content/dashboard/Aboutus'))
);
const EditorialClaims = Loader(
  lazy(() => import('src/content/dashboard/editorialClaims'))
);

const DocumentsS3 = Loader(
  lazy(() => import('src/content/dashboard/Documents'))
);
const ImagePorcessS3 = Loader(
  lazy(() => import('src/content/dashboard/ImageProcess'))
);
const FilesS3 = Loader(lazy(() => import('src/content/dashboard/Files2')));
const ViewS3 = Loader(lazy(() => import('src/content/dashboard/View')));
const ViewsS3 = Loader(lazy(() => import('src/content/dashboard/Views')));
const ViewerS3 = Loader(lazy(() => import('src/content/dashboard/Viewer')));
const ImageViewS3 = Loader(
  lazy(() => import('src/content/dashboard/ImageView'))
);
const CopyPaste = Loader(lazy(() => import('src/content/dashboard/copyPaste')));
const RealTime = Loader(lazy(() => import('src/content/dashboard/Realtime')));
const Texract = Loader(lazy(() => import('src/content/dashboard/textract')));
const ImageSearch = Loader(lazy(() => import('src/content/dashboard/SearchImage')));


// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: (
      <SessionMaintained>
        <BaseLayout />
      </SessionMaintained>
    ),
    children: [
      {
        path: '/',
        element: <Authentication />
      },
      {
        path: Page.Login,
        element: <Authentication />
      },
      {
        path: 'msearch',
        children: [
          {
            path: '/',
            element: <MSearchBox />
          }
        ]
      },
      {
        path: 'mview',
        children: [
          {
            path: '/',
            element: <MSearchView />
          }
        ]
      },
      // {
      //   path: 'test',
      //   children: [
      //     {
      //       path: '/',
      //       element: <TestS3 />
      //     }
      //   ]
      // },
      {
        path: 'register',
        children: [
          {
            path: '/',
            element: <Register />
          }
        ]
      },
      {
        path: 'Awsauth',
        children: [
          {
            path: '/',
            element: <AWSauth />
          }
        ]
      },
      {
        path: 'AccountCreated',
        children: [
          {
            path: '/',
            element: <AccountCreated />
          }
        ]
      },
      {
        path: 'forget',
        children: [
          {
            path: 'password',
            element: <Forgetemail />
          },
          {
            path: 'authcode',
            element: <Forgetauth />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  // {
  //   path: '*',
  //   element: (
  //     <ProtectedRoute>
  //       <SidebarLayout />
  //     </ProtectedRoute>
  //   ),
  //   children: [
  //     {
  //       path: 'rules',
  //       children: [
  //         {
  //           path: '/',
  //           element: <CaseSettingsS3 />
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: eDiscoveryUrl.Rules,
        children: [
          {
            path: '/',
            element: <CaseSettingsS3 />
          }
        ]
      },
      {
        path: eDiscoveryUrl.EditorialClaims,
        children: [
          {
            path: '/',
            element: <EditorialClaims />
          }
        ]
      }
      
    ]
  },

  {
    path: Page.DashboardsS3,
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '/',
        element: <Navigate to={Page.CaseS3} replace />
      },
      {
        path: Page.copyPaste,
        element: <CopyPaste />
      },
      {
        path: Page.RealTime,
        element: <RealTime />
      },
      {
        path: 'texract',
        element: <Texract />
      },
      
      {
        path: `${Page.CaseS3}/:caseid`,
        element: <DocumentsS3 />
      },
      {
        path: `${Page.ImagePorcessS3}/:caseid`,
        element: <ImagePorcessS3 />
      },

      {
        path: 'files/:caseid/:docid',
        element: <FilesS3 />
      },
      {
        path: 'view/:caseid/:docid/:filename',
        element: <ViewS3 />
      },
      {
        path: 'views/:caseid/:docid/:filename',
        element: <ViewsS3 />
      },
      {
        path: 'viewer/:caseid/:docid/:filename',
        element: <ViewerS3 />
      },
      {
        path: 'image/view/:caseid/:docid/:filename',
        element: <ImageViewS3 />
      },
      {
        path: 'search-image/',
        element: <ImageSearch />
      }
      
      // {
      //   path: `${Page.AdvanceSearch}`,
      //   element: <AdvanceSearch />
      // }
      // ,
      // {PSTPIIDocuments
      //   path: 'Test',
      //   element: <Test />
      // }
    ]
  }
];

export default routes;
